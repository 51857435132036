// import { mapState, mapMutations } from 'vuex'
import { logger } from '../logger'
import { mapState, mapGetters, mapActions } from 'vuex'
import i18n from '@/i18nVeeValidate'

export default {
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),

		...mapGetters('profile', {
			isLoaded: 'isLoaded',
		}),
	},
	created() {
		// logger.info('mixing in validation required !')
	},

	methods: {
		...mapActions('profile', {
			loadCurrentUser: 'getCurrentUser',
		}),
	},

	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			if (!vm.isLoaded) {
				await vm.loadCurrentUser()
			}
			let verificationRequired = vm.currentUser?.status === 0 || (vm.currentUser.parent ? vm.currentUser?.parent.emailVerified !== true : vm.currentUser?.emailVerified !== true)

			if (to.meta.admin) {
				if (vm.currentUser.admin && !verificationRequired) {
					return next()
				} else {
					return next('/')
				}
			}

			if (!vm.currentUser.status || verificationRequired) {
				if (from.name !== 'signup') {
					vm.flash(i18n.t('error.emailVerificationPending'), 'error')
				}
				return next({ name: 'emailValidation', query: { from: to.fullPath } })
			}

			return next()
		})
	},
}
