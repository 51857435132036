import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { omit, isNil } from 'lodash/fp'
import { logger } from '@/logger'
import router from '@/router'
import { userProps } from '@/store/modules/profile'
import i18n from '@/i18nVeeValidate'
import session from '@/store/modules/session'

// Import { filter, omit } from 'lodash/fp'

// Initial state
const initialState = () => ({
	isLoggedIn: !isNil(localStorage.getItem('cycloToken')),
	showTelForm: false,
	valid: true,
	isLoading: false,
	passwordReset: {
		userId: null,
		phoneNumber: null,
		resetCode: null,
	},
	loginInfo: '',
})

const state = initialState()

// const userProps = `
// 		id
// 		firstName
// 		lastName
// 		email
// 		phoneNumber
// 		language
// 		newsletter
// 		status
// 		mobib
// 	`

// Getters
const getters = {
	// IsLoggedIn: () => {
	// 	Return !isEmpty(localStorage.getItem('token'))
	// },
}

const mutateValidateToken = (pin) =>
	graphqlClient.mutate({
		mutation: gql`
			mutation verifyPhoneNumber($pin: String!) {
				verifyPhoneNumber(pin: $pin)
			}
		`,
		variables: {
			pin,
		},
	})
const mutateValidateEmailToken = (pin) =>
	graphqlClient.mutate({
		mutation: gql`
			mutation verifyEmail($pin: String!) {
				verifyEmail(pin: $pin)
			}
		`,
		variables: {
			pin,
		},
	})

const mutateVerifyResetToken = (pin, userId) =>
	graphqlClient.mutate({
		mutation: gql`
			mutation verifyResetToken($pin: String!, $userId: Int!) {
				verifyResetToken(pin: $pin, userId: $userId)
			}
		`,
		variables: {
			pin,
			userId,
		},
	})

const actions = {
	async loginUser({ dispatch, commit }, payload) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
				mutation LogInUser($email: String!, $password: String!) {
					logInUser(email: $email, password: $password) {
						token
						user {
							${userProps}
						}
						info
					}
				}
			`,
				variables: {
					email: payload.email,
					password: payload.password,
				},
			})

			if (response.data.logInUser.user) {
				dispatch('profile/setCurrentUser', response.data.logInUser.user, { root: true })

				commit('setToken', response.data.logInUser.token)
				commit('resetState')
				// Check active session for current user
				const queryResponse = await graphqlClient.query({
					query: gql`
						query UserLiveSession($userId: Int) {
							userLiveSession(userId: $userId) {
								id
								userId
								parkingId
								sessionQRCodes {
									id
									userSessionId
									qrType
									qrCode
									used
									usage
									usedAt
									expiresIn
								}
								bikes
								sessionId
								paymentMethod
								startTime
								endTime
								duration
								totalAmountPaid
								createdAt
							}
						}
					`,
					variables: { userId: response.data.logInUser.user.id },
					fetchPolicy: 'no-cache',
				})

				if (queryResponse.data.userLiveSession) {
					const sessionData = queryResponse.data.userLiveSession
					const mainDoorEnterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDENT')
					const mainDoorExtQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDEXT')
					const mainDoorPickupEnterQr = sessionData.sessionQRCodes.filter(
						(a) => a.qrType === 'MDPENT'
					)
					const mainDoorPickupExitQr = sessionData.sessionQRCodes.filter(
						(a) => a.qrType === 'MDPEXT'
					)
					const firstEnterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'FENT')
					const firstExitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'FEXT')
					const enterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'ENT')
					const exitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'EXT')
					console.log('sessionData', sessionData)
					if (mainDoorEnterQr.length > 0 && mainDoorEnterQr[0].used < mainDoorEnterQr[0].usage) {
						sessionData.sessionQRCodes = mainDoorEnterQr
						commit('session/mainDoorEnter', sessionData, { root: true })
					} else if (firstEnterQr[0].used < firstEnterQr[0].usage) {
						sessionData.sessionQRCodes = firstEnterQr
						commit('session/addSession', sessionData, { root: true })
					} else if (firstExitQr[0].used < firstExitQr[0].usage) {
						sessionData.sessionQRCodes = firstExitQr
						commit('session/firstParkingExit', sessionData, { root: true })
					} else if (mainDoorExtQr.length > 0 && mainDoorExtQr[0].used < mainDoorExtQr[0].usage) {
						sessionData.sessionQRCodes = mainDoorExtQr
						commit('session/mainDoorExit', sessionData, { root: true })
					} else if (firstExitQr[0].used === firstExitQr[0].usage && enterQr.length === 0) {
						commit('session/activeSession', sessionData, { root: true })
					} else if (
						mainDoorPickupEnterQr.length > 0 &&
						mainDoorPickupEnterQr[[mainDoorPickupEnterQr.length - 1]].used <
							mainDoorPickupEnterQr[[mainDoorPickupEnterQr.length - 1]].usage
					) {
						sessionData.sessionQRCodes = mainDoorPickupEnterQr
						commit('session/mainDoorEnter', sessionData, { root: true })
					} else if (
						enterQr.length > 0 &&
						enterQr[enterQr.length - 1].used < enterQr[enterQr.length - 1].usage
					) {
						sessionData.sessionQRCodes = enterQr
						commit('session/stopSession', sessionData, { root: true })
					} else if (exitQr[0].used < exitQr[0].usage) {
						sessionData.sessionQRCodes = exitQr
						commit('session/endSession', sessionData, { root: true })
					} else if (
						mainDoorPickupExitQr.length > 0 &&
						mainDoorPickupExitQr[0].used < mainDoorPickupExitQr[0].usage
					) {
						sessionData.sessionQRCodes = mainDoorPickupExitQr
						commit('session/mainDoorExit', sessionData, { root: true })
					}
					// set active session id into local storage
					localStorage.setItem('sessionId', sessionData.sessionId)
				}
			} else {
				dispatch('alert/error', i18n.t('error.loginFailed'), { root: true })
				commit('loginFailed', response.data.logInUser.info)
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
			commit('loginFailed')
		}
	},
	async createUser({ commit, dispatch }, payload) {
		const user = omit(
			['phonePrefix'],
			Object.assign({}, payload, {
				// phoneNumber: payload.phonePrefix.key + payload.phoneNumber,
				phoneNumber: payload.phoneNumber.replace(/ /g, ''),
				language: payload.language.key,
			})
		)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createUser($user: UserCreateProps!) {
						createUser(props: $user) {
							token,
							user {
								${userProps}
							}
						}
					}
				`,
				variables: {
					user,
				},
			})

			if (response.data.createUser) {
				dispatch('profile/setCurrentUser', response.data.createUser.user, { root: true })
				commit('setToken', response.data.createUser.token)
			}
		} catch (e) {
			logger.error('hard catch create error in action')
			dispatch('alert/error', e.message, { root: true })
			throw e
		}
	},
	async resetPassword({ dispatch, commit }, { password, resetCode }) {
		if (!resetCode) {
			dispatch('alert/error', i18n.t('error.noResetCodeProvided'), { root: true })

			return false
		}

		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation ResetPassword($password: String!, $resetCode: String!) {
						resetPassword(password: $password, resetCode: $resetCode)
					}
				`,
				variables: {
					password,
					resetCode,
				},
			})

			if (response.data.resetPassword) {
				dispatch('alert/success', i18n.t('flashMessage.passwordResetSuccessful'), { root: true })
				router.push({ name: 'login' })

				return response.data.resetPassword
			} else {
				dispatch('alert/error', i18n.t('error.passwordResetFailed'), {
					root: true,
				})

				return false
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })

			return false
		} finally {
			commit('setLoading', true)
			commit('resetState')
		}
	},

	async updatePassword({ dispatch }, { oldPassword, newPassword }) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
						updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
					}
				`,
				variables: {
					oldPassword,
					newPassword,
				},
			})

			if (response.data.updatePassword) {
				dispatch('alert/success', i18n.t('flashMessage.passwordUpdate'), { root: true })

				return response.data.updatePassword
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })

				return false
			}
		} catch (e) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })

			return false
		}
	},

	async validateToken({ commit, dispatch }, pin) {
		commit('setLoading', true)

		try {
			const response = await mutateValidateToken(pin)

			if (response.data.verifyPhoneNumber) {
				dispatch('alert/success', i18n.t('flashMessage.accountActivationDone'), { root: true })
				dispatch('profile/setHasVerificationId', false, { root: true })
				commit('setShowTelForm', false)
				commit('profile/activatePhoneNumber', response.data.verifyPhoneNumber, { root: true })
				logger.warn('going after auth fron authentication module')
				dispatch('goAfterAuth', null, { root: true })
				//router.push({ name: 'profile' })
			} else {
				dispatch('alert/error', i18n.t('error.accountActivationFailed'), { root: true })
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async validateEmailToken({ commit, dispatch }, pin) {
		commit('setLoading', true)

		try {
			const response = await mutateValidateEmailToken(pin)

			if (response.data.verifyEmail) {
				dispatch('alert/success', i18n.t('flashMessage.accountActivationDone'), { root: true })
				dispatch('profile/setHasVerificationId', false, { root: true })
				commit('profile/activateAccount', response.data.verifyPhoneNumber, { root: true })
				commit('setShowTelForm', false)
				if (router.history.current.name !== 'profile') {
					dispatch('goAfterAuth', null, { root: true })
				}
				logger.warn('going after auth fron authentication module')
			} else {
				dispatch('alert/error', i18n.t('error.accountActivationFailed'), { root: true })
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async validateResetToken({ commit, dispatch }, { pin, userId, type }) {
		commit('setLoading', true)

		try {
			const response = await mutateVerifyResetToken(pin, userId)
			console.log('response', response)
			if (response.data.verifyResetToken) {
				// dispatch('alert/success', 'Account activation successful', { root: true })

				commit('setResetCode', response.data.verifyResetToken)
				commit('setPasswordResetUser', null)
				router.push({ name: 'resetPassword' })
			} else {
				if (type == 'email') {
					dispatch('alert/error', i18n.t('error.emailVerficiationFailed'), { root: true })
				} else {
					dispatch('alert/error', i18n.t('error.phoneVerificationFailed'), { root: true })
				}
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
		} finally {
			commit('setLoading', false)
		}
	},

	async requestToken({ dispatch, commit }, { phone, password = null }) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.query({
				query: gql`
					query RequestVerification($phone: PhoneNumber, $password: String) {
						requestVerification(phone: $phone, password: $password)
					}
				`,
				variables: {
					phone,
					password,
				},
				fetchPolicy: 'network-only',
			})

			if (response.data.requestVerification) {
				dispatch('alert/success', i18n.t('flashMessage.verificationCodeRequested'), { root: true })
				dispatch('profile/setHasVerificationId', true, { root: true })
				commit('setShowTelForm', true)
				// // Get current 'from' query parameter if exists
				// const currentFrom = router.currentRoute.query.from || ''
				// // router.push({ name: 'verifyPhone' })
				// console.log('router', router)
				// if (router.currentRoute.name !== 'verifyPhone') {
				// 	router.push({
				// 		name: 'verifyPhone',
				// 		params: { password },
				// 		query: { from: currentFrom },
				// 	})
				// }
			} else {
				dispatch('alert/error', i18n.t('error.activationRequestFailed'), { root: true })
			}

			commit('setLoading', false)
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
			commit('setLoading', false)
		}
	},

	async requestEmailToken({ dispatch, commit }, { email, password = null }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query RequestVerificationEmail($email: String, $password: String) {
						requestVerificationEmail(email: $email, password: $password)
					}
				`,
				variables: {
					email,
					password,
				},
				fetchPolicy: 'network-only',
			})

			if (response.data.requestVerificationEmail) {
				dispatch('alert/success', i18n.t('flashMessage.verificationCodeRequested'), { root: true })
				dispatch('profile/setHasVerificationId', true, { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.activationRequestFailed'), { root: true })
			}

			commit('setLoading', false)
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
			commit('setLoading', false)
		}
	},

	async requestPhoneAuth({ dispatch, commit }, phone) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query RequestPhoneAuthentication($phone: PhoneNumber) {
						requestPhoneAuthentication(phone: $phone)
					}
				`,
				variables: {
					phone,
				},
				fetchPolicy: 'network-only',
			})

			if (response.data.requestPhoneAuthentication) {
				commit('setPasswordResetUser', response.data.requestPhoneAuthentication)
				commit('setPhoneNumberUser', phone)
				dispatch('alert/success',  i18n.t('flashMessage.activationCodeRequested'), { root: true })
				router.push({ name: 'ForgotPasswordValidation' })
			} else {
				dispatch('alert/error', i18n.t('error.activationRequestFailedError'), { root: true })
			}

			commit('setLoading', false)
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
			commit('setLoading', false)
		}
	},

	async requestEmailAuth({ dispatch, commit }, email) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.query({
				query: gql`
					query RequestEmailAuthentication($email: String) {
						requestEmailAuthentication(email: $email)
					}
				`,
				variables: {
					email,
				},
				fetchPolicy: 'network-only',
			})

			if (response.data.requestEmailAuthentication) {
				commit('setPasswordResetUser', response.data.requestEmailAuthentication)
				commit('setEmailUser', email)
				dispatch('alert/success', i18n.t('flashMessage.activationCodeRequested'), { root: true })
				router.push({ name: 'ForgotPasswordValidation' })
			} else {
				dispatch('alert/error', i18n.t('error.activationRequestFailedError'), { root: true })
			}

			commit('setLoading', false)
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })
			commit('setLoading', false)
		}
	},

	logout({ commit, dispatch }) {
		commit('logout')
		dispatch('profile/resetState', '', { root: true })
		dispatch('session/resetState', '', { root: true })
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},
	setToken(state, token) {
		localStorage.setItem('cycloToken', token)
		state.isLoggedIn = true
	},
	setShowTelForm(state, status) {
		state.showTelForm = status
	},
	loginFailed(state, info = '') {
		Object.assign(state, initialState())
		state.valid = false
		state.loginInfo = info
	},
	logout(state) {
		localStorage.removeItem('cycloToken')
		localStorage.removeItem('sessionId')
		localStorage.removeItem('sessionBikes')
		Object.assign(state, initialState())
	},
	setLoading(state, status) {
		state.isLoading = status
	},

	setPasswordResetUser(state, userId) {
		state.passwordReset.userId = userId
	},
	setResetCode(state, code) {
		state.passwordReset.resetCode = code
	},
	setPhoneNumberUser(state, phone) {
		state.passwordReset.phoneNumber = phone
	},
	setEmailUser(state, email) {
		state.passwordReset.email = email
	},
}

export default {
	namespaced: true,
	state,
	getters,
	modules: {
		session,
	},
	actions,
	mutations,
}
